import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'
import parse from 'html-react-parser'

const query = graphql`
	{
		site {
			siteMetadata {
				defaultTitle: title
				titleTemplate
				defaultDescription: description
				siteUrl: url
				defaultImage: image
				defaultKeywords: keywords
			}
		}
	}
`

function SEO({ title, description, image, pathname, article, keywords }) {
  return (
    <StaticQuery
      query={query}
      render={({
                 site: {
                   siteMetadata: {
                     defaultKeywords,
                     defaultTitle,
                     titleTemplate,
                     defaultDescription,
                     siteUrl,
                     defaultImage,
                   },
                 },
               }) => {
        const seo = {
          title: title || defaultTitle,
          description: description || defaultDescription,
          image: `${siteUrl}${image || defaultImage}`,
          url: `${siteUrl}${pathname || '/'}`,
          keywords: keywords || defaultKeywords,
        }
        return (
          <>
            <Helmet title={parse(seo.title)} titleTemplate={titleTemplate}>
              <html lang="nl" />
              <meta name="description" content={seo.description} />
              <meta name="keywords" content={seo.keywords} />

              <meta name="image" content={seo.image} />
              {seo.url && <meta property="og:url" content={seo.url} />}
              {(article ? true : null) && (
                <meta property="og:type" content="article" />
              )}
              {seo.title && <meta property="og:title" content={seo.title} />}
              {seo.description && (
                <meta property="og:description" content={seo.description} />
              )}
              {seo.image && <meta property="og:image" content={seo.image} />}
              <meta name="twitter:card" content="summary_large_image" />
              {seo.title && <meta name="twitter:title" content={seo.title} />}
              {seo.description && (
                <meta name="twitter:description" content={seo.description} />
              )}
              {seo.image && <meta name="twitter:image" content={seo.image} />}
              {seo.keywords && <meta name="keywords" content={seo.keywords} />}
            </Helmet>
          </>
        )
      }}
    />
  )
}

export default SEO
